import React, {Component} from 'react';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';

import http from 'services/http';
import ListaResponsiva from 'components/ListaResponsiva/Acompanhamento';
import Bilhete from 'components/Bilhete';
import BilheteBolao from 'components/BilheteBolao/index';
import PermissionGate from "../../../utils/PermissionGate";
import {alerta} from "../../../components/Alert";
import formatMoney from "../../../utils/formatMoney";
import BilheteSeninha from "../../../components/BilheteSeninha";
import BilheteSenaBrasil from "../../../components/BilheteSenaBrasil";
import BilheteBolaoDezena from "../../../components/BilheteBolaoDezena";
import BilheteDoisQuinhentos from "../../../components/BilheteDoisQuinhentos";
import BilheteRifa from "../../../components/BilheteRifa";

const tHead = ['Código', 'Data', 'Operador', 'Valor', 'Prêmio', 'Extração'];

const situacao = [
    {label: 'Pago/Vencedor', value: 'pago-vencedor'},
    {label: 'Vencedor', value: 'vencedor'},
    {label: 'Pago', value: 'pago'},
    {label: 'Todos', value: 'todos'},
    {label: 'Pendente', value: 'pendente'},
    {label: 'Perdedor', value: 'perdedor'},
    {label: 'Cancelado', value: 'cancelado'}
];

const ordernarPor = [{
    label: 'Data', value: 'data'
}, {
    label: 'Valor', value: 'valor'
}, {
    label: 'Prêmio', value: 'premio'
}];

export default class index extends Component {
    constructor(props) {
        super(props);

        const modulosStoreged = JSON.parse(localStorage.getItem('usuario')).modulosAtivos;

        if (modulosStoreged.includes('JB')) {
            this.tipo = 'jb';
        } else if (modulosStoreged.includes('Lototime')) {
            this.tipo = 'lototime';
        } else if (modulosStoreged.includes('2 para 500')) {
            this.tipo = 'Dois Para Quinhentos';
        } else {
            this.tipo = modulosStoreged[0]
        }

        this.state = {
            data1: dayjs().format('YYYY-MM-DD'),
            data2: dayjs().format('YYYY-MM-DD'),
            arrayDatas: [],
            status: 'vencedor',
            acompanhamento: [],
            listaOperadores: [],
            listaRotas: [],
            listaLoteria: [],
            operadorId: '',
            rotaId: '',
            loteriaId: '',
            numeroBilhete: '',
            situacao: 'pago-vencedor',
            exibirModal: false,
            ordernarPor: 'data',
            aposta: [],
            situacaoAposta: '',
            tipo: this.tipo,
            premioAposta: '',
            loadingAtivo: true,
            indexAposta: '',
            nivel: '',
            modulos: modulosStoreged,
            baixaAutomatica: JSON.parse(localStorage.getItem('usuario')).baixa_automatica
        };

        this.state.modulos.includes('2 para 500')
    }


    componentDidMount() {
        const buscaNivel = async () => {
            const nivel = await localStorage.getItem('nivel');
            this.setState({nivel});
        }
        buscaNivel();

        if (localStorage.getItem('nivel') === 'admin') {
            this.requestOperadores();
            this.requestRotas();
            this.requestLoteria();
        }

        this.requestBilhetes();
        this.criarDatas();
    }

    requestBilhetes = async () => {
        this.setState({loadingAtivo: true});
        try {

            let params;

            if (this.state.nivel === 'gerente') {
                params = {
                    numero_bilhete: this.state.numeroBilhete,
                    situacao: this.state.situacao,
                    data_inicial: this.state.data1,
                    data_final: this.state.data2,
                }
            } else {
                params = {
                    data_inicial: this.state.data1,
                    data_final: this.state.data2,
                    operador_id: this.state.operadorId,
                    gerente_id: this.state.rotaId,
                    loteria_id: this.state.loteriaId,
                    numero_bilhete: this.state.numeroBilhete,
                    situacao: this.state.situacao,
                    ordernar_por: this.state.ordernarPor,
                }
            }

            let response;
            if (this.state.tipo === 'jb' || this.state.tipo === 'lototime') {
                response = await http.get(this.state.tipo + '/apostas', {
                    params
                });
            }

            if (this.state.tipo === 'Bolao') {
                response = await http.get('/boloes/apostas', {
                    params
                });
            }

            if (this.state.tipo === 'Bolao de Dezenas') {
                response = await http.get('/boloes-dezena/palpites', {
                    params
                });
            }

            if (this.state.tipo === 'Seninha' || this.state.tipo === 'Quininha' || this.state.tipo === 'Lotinha') {
                response = await http.get(`/seninha/palpites/${this.state.tipo}`, {
                    params
                });
            }

            if (this.state.tipo === 'Sena Brasil' || this.state.tipo === 'Quina Brasil' || this.state.tipo === 'Loto Brasil') {
                response = await http.get(`/sena-brasil/palpites/${this.state.tipo}`, {
                    params
                });
            }

            if (this.state.tipo === 'Dois Para Quinhentos') {
                response = await http.get('/doisquinhentos/apostas', {
                    params
                });
            }

            if (this.state.tipo === 'Rifa') {
                response = await http.get('/rifas/apostas', {
                    params
                });
            }

            const {data: acompanhamento} = response.data;
            this.setState({acompanhamento});

        } catch (e) {
            alerta(e.response.data)
        }

        this.setState({loadingAtivo: false});
    };

    abrirAcompanhemtno = async (codigo, index) => {
        try {
            const response = await http.get(this.state.tipo + '/apostas/' + codigo);

            const {data: aposta} = response.data;

            this.setState({
                aposta, indexAposta: index, exibirModal: true
            });
        } catch (e) {
            console.log(e);
        }
    };

    criarDatas = () => {
        const datas = [];

        for (let i = 0; i < 15; i++) {
            const data = dayjs().subtract(i, 'day');
            datas.push({
                label: data.format('DD/MM/YYYY'),
                value: data.format('YYYY-MM-DD'),
                dataInicial: data.format('YYYY-MM-DD'),
                dataFinal: data.format('YYYY-MM-DD')
            });
        }

        const data = dayjs();

        datas.push({
            label: 'Últimos 7 dias',
            value: '7',
            dataFinal: data.format('YYYY-MM-DD'),
            dataInicial: data.subtract(6, 'day').format('YYYY-MM-DD')
        });

        datas.push({
            label: 'Últimos 15 dias',
            value: '15',
            dataFinal: data.format('YYYY-MM-DD'),
            dataInicial: data.subtract(14, 'day').format('YYYY-MM-DD')
        });

        datas.push({
            label: 'Últimos 30 dias',
            value: '30',
            dataFinal: data.format('YYYY-MM-DD'),
            dataInicial: data.subtract(29, 'day').format('YYYY-MM-DD')
        });

        this.setState({arrayDatas: datas});
    };

    requestOperadores = async (consulta) => {
        try {
            const response = await http.get('/usuarios', {
                params: {
                    nivel: 'operador', resumida: true, gerente_id: consulta
                }
            });

            const {data: usuarios} = response.data;

            this.setState({listaOperadores: usuarios});
        } catch (e) {
        }
    };

    requestRotas = async () => {
        try {
            const response = await http.get('/usuarios', {
                params: {
                    nivel: 'gerente', resumida: true
                }
            });

            const {data: usuarios} = response.data;

            this.setState({listaRotas: usuarios});
        } catch (e) {
        }
    };

    requestLoteria = async () => {
        try {
            const modulos = JSON.parse(localStorage.getItem('usuario')).modulosAtivos;

            if (!modulos.includes('JB') && !modulos.includes('Lototime')) {
                return;
            }

            const response = await http.get('/loterias');
            const {data: loterias} = response.data;
            this.setState({listaLoteria: loterias});
        } catch (e) {
        }
    };

    abrirModal = (codigo, index) => {
        this.abrirAcompanhemtno(codigo, index);
    };

    mudarOperador = async (id) => {
        const {listaOperadores} = this.state;
        const idUsuario = parseInt(id);

        const operador = listaOperadores.find((usuario) => usuario.id === idUsuario);

        this.setState({
            operadorId: operador ? operador.id : 0
        });
    };

    mudarRota = async (id) => {
        const {listaRotas} = this.state;
        const idUsuario = parseInt(id);
        const rota = listaRotas.find((usuario) => usuario.id === idUsuario);

        rota ? this.requestOperadores(rota.id) : this.requestOperadores();

        this.setState({
            rotaId: rota ? rota.id : 0
        });
    };

    mudarLoteria = async (id) => {
        const {listaLoteria} = this.state;
        const idLoteria = parseInt(id);

        const loteria = listaLoteria.find((loteria) => loteria.id === idLoteria);

        this.setState({
            loteriaId: loteria ? loteria.id : 0
        });
    };

    rangerButton2 = async (codigo, index, situacao) => {
        try {
            await http.patch(`${this.state.tipo}/apostas/${codigo}/cancelar`);

            const {acompanhamento} = this.state;

            acompanhamento[index].situacao = situacao;

            this.setState({acompanhamento});
        } catch (e) {
        }
    };

    cancelaBilheteBolao = async (codigo) => {
        try {
            Swal.fire({
                title: 'Deseja cancelar a aposta?',
                text: 'Essa ação não poderá ser revertida!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                reverseButtons: true,
                confirmButtonText: 'Sim, desejo cancelar!',
                cancelButtonText: 'Não, voltar'
            }).then(async (result) => {
                if (result.value) {
                    await http.patch(`/boloes/apostas/${codigo}/cancelar`);
                    this.requestBilhetes();
                }
            });
        } catch (e) {
        }
    };

    cancelaBilheteBolaoDezena = async (id) => {
        try {
            Swal.fire({
                title: 'Deseja cancelar a aposta?',
                text: 'Essa ação não poderá ser revertida!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                reverseButtons: true,
                confirmButtonText: 'Sim, desejo cancelar!',
                cancelButtonText: 'Não, voltar'
            }).then(async (result) => {
                if (result.value) {
                    await http.patch(`/boloes-dezena/palpites/${id}/cancelar`);
                    this.requestBilhetes();
                }
            });
        } catch (e) {
        }
    };

    cancelaBilheteSeninha = async (codigo) => {
        try {
            Swal.fire({
                title: 'Deseja cancelar a aposta?',
                text: 'Essa ação não poderá ser revertida!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                reverseButtons: true,
                confirmButtonText: 'Sim, desejo cancelar!',
                cancelButtonText: 'Não, voltar'
            }).then(async (result) => {
                if (result.value) {
                    await http.patch(`/seninha/palpites/${codigo}/cancelar`);
                    this.requestBilhetes();
                }
            });
        } catch (e) {
        }
    }

    cancelaBilheteDoisQuinhentos = async (codigo) => {
        try {
            Swal.fire({
                title: 'Deseja cancelar a aposta?',
                text: 'Essa ação não poderá ser revertida!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                reverseButtons: true,
                confirmButtonText: 'Sim, desejo cancelar!',
                cancelButtonText: 'Não, voltar'
            }).then(async (result) => {
                if (result.value) {
                    await http.delete(`/doisquinhentos/apostas/${codigo}/cancelar`);
                    this.requestBilhetes();
                }
            });
        } catch (e) {
        }
    }

    cancelaBilheteSenaBrasil = async (codigo) => {
        try {
            Swal.fire({
                title: 'Deseja cancelar a aposta?',
                text: 'Essa ação não poderá ser revertida!',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                reverseButtons: true,
                confirmButtonText: 'Sim, desejo cancelar!',
                cancelButtonText: 'Não, voltar'
            }).then(async (result) => {
                if (result.value) {
                    await http.patch(`/sena-brasil/palpites/${codigo}/cancelar`);
                    this.requestBilhetes();
                }
            });
        } catch (e) {
        }
    }

    alterarAposta = async () => {
        try {
            const {
                aposta: {codigo}, situacaoAposta
            } = this.state;

            await http.put(`${this.state.tipo}/apostas/${codigo}`, {
                situacao: situacaoAposta
            });

            this.state.acompanhamento[this.state.indexAposta].situacao = situacaoAposta;

            this.setState({exibirModal: false});
        } catch (e) {
        }
    };

    pagarTodaasApostas = async () => {

        Swal.fire({
            title: 'Deseja pagar todas as apostas vencedoras?',
            text: 'Essa ação não poderá ser revertida!',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            reverseButtons: true,
            confirmButtonText: 'Sim, desejo!',
            cancelButtonText: 'Não, voltar'
        }).then(async (result) => {
            if (result.value) {
                try {
                    if (result.value) {

                        this.setState({loadingAtivo: true});
                        for (let i = 0; i < this.state.acompanhamento.length; i++) {
                            if (this.state.acompanhamento[i].situacao === 'vencedor') {

                                if (this.state.tipo === 'jb' || this.state.tipo === 'lototime') {
                                    await http.put(`${this.state.tipo}/apostas/${this.state.acompanhamento[i].codigo}`, {
                                        situacao: 'pago', premio: this.state.acompanhamento[i].premio
                                    });
                                } else {
                                    await http.put(`/boloes/apostas/${this.state.acompanhamento[i].id}/pagar`);
                                }

                            }
                        }
                        this.requestBilhetes();
                        this.setState({loadingAtivo: false});
                        Swal.fire({
                            type: 'success', title: 'Pulas pagas com sucesso!'
                        });
                    }
                } catch (error) {
                    Swal.fire({
                        type: 'error', title: 'Erro ao tentar pagar todos!'
                    });
                } finally {
                    this.setState({loadingAtivo: false});
                }
            }
        });

    };

    editarAposta = () => {
        const {aposta} = this.state;

        aposta.editar = !aposta.editar;

        this.setState({
            aposta, situacaoAposta: aposta.situacao, premioAposta: aposta.premio
        });
    };

    modalBilhete = () => {
        return (this.state.exibirModal && (<div
            className="modal"
            style={{
                display: 'block', overflowY: 'scroll', maxHeight: '100%'
            }}
        >
            <div className="modal-dialog">
                <div className="modal-content modal-bilhete">
                    <div className="modal-body">
                        {(this.state.aposta.editar && this.state.nivel !== 'gerente')
                            ? (<div
                                style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    margin: '20px auto',
                                    justifyContent: 'space-evenly'
                                }}
                            >
                                <select
                                    required
                                    className="form-control text-capitalize"
                                    style={{width: '100%'}}
                                    id="inputEstado"
                                    onChange={(e) => {
                                        this.setState({situacaoAposta: e.target.value});
                                    }}
                                    value={this.state.situacaoAposta}
                                >
                                    <option value="cancelado">Cancelado</option>
                                    <option value="perdedor">Perdedor</option>
                                    <option value="vencedor">Vencedor</option>
                                    <option value="pago">Pago</option>
                                    <option value="pendente">Pendente</option>
                                </select>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    style={{
                                        width: '94%', height: '38px', marginTop: '7px'
                                    }}
                                    onClick={() => this.alterarAposta()}
                                >
                                    Salvar
                                </button>
                            </div>)
                            :
                            ((this.state.tipo === 'jb' || this.state.tipo === 'lototime') && this.state.nivel !== 'gerente') && (
                                <button
                                    type="button"
                                    className="btn btn-light"
                                    style={{
                                        width: '100%', margin: '0 0 10px 0', display: 'block'
                                    }}
                                    onClick={() => this.editarAposta()}
                                >
                                    Editar
                                </button>)}
                        <div className="center">
                            {(this.state.tipo === 'jb' || this.state.tipo === 'lototime') && (
                                <Bilhete aposta={this.state.aposta} nivel={this.state.nivel} tipo={this.state.tipo}/>)}
                            {this.state.tipo === 'Bolao' && (<BilheteBolao aposta={this.state.aposta}/>)}
                            {(this.state.tipo === 'Seninha' || this.state.tipo === 'Quininha' || this.state.tipo === 'Lotinha') && (
                                <BilheteSeninha aposta={this.state.aposta}/>)}
                            {(this.state.tipo === 'Dois Para Quinhentos') && (
                                <BilheteDoisQuinhentos aposta={this.state.aposta}/>)}
                            {(this.state.tipo === 'Rifa') && (
                                <BilheteRifa aposta={this.state.aposta}/>)}
                            {(this.state.tipo === 'Sena Brasil' || this.state.tipo === 'Quina Brasil' || this.state.tipo === 'Loto Brasil') && (
                                <BilheteSenaBrasil aposta={this.state.aposta}/>)}
                            {this.state.tipo === 'Bolao de Dezenas' && <BilheteBolaoDezena aposta={this.state.aposta}/>}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            style={{width: '100%'}}
                            type="button"
                            className="btn btn-danger"
                            data-dismiss="modal"
                            onClick={() => this.setState({exibirModal: false})}
                        >
                            Fechar
                        </button>
                    </div>
                </div>
            </div>
        </div>));
    };

    render() {

        return (<>
            <div className="titulo-principal">Bilhetes</div>
            {this.state.nivel !== 'gerente'
                ? <div className="conteudo-principal">
                    <div className="d-flex tres-colunas">
                        <div className="form-group">
                            <label>Gerente</label>
                            <select
                                onChange={(e) => this.mudarRota(e.target.value)}
                                className="form-control"
                            >
                                <option value="0">Gerentes</option>
                                {this.state.listaRotas.map((u) => (<option key={u.id} value={u.id}>
                                    {u.login.toUpperCase()}
                                </option>))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Cambista</label>
                            <select
                                onChange={(e) => this.mudarOperador(e.target.value)}
                                className="form-control"
                            >
                                <option value="0">Cambistas</option>
                                {this.state.listaOperadores.map((u) => (<option key={u.id} value={u.id}>
                                    {u.login.toUpperCase()}
                                </option>))}
                            </select>
                        </div>

                        <div className="form-group">
                            <label>Situação</label>
                            <select
                                onChange={(e) => this.setState({situacao: e.target.value})}
                                className="form-control"
                            >
                                {situacao.map((situacao) => (<option key={situacao.value} value={situacao.value}>
                                    {situacao.label}
                                </option>))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Ordernar por</label>
                            <select
                                onChange={(e) => this.setState({ordernarPor: e.target.value})}
                                className="form-control"
                            >
                                {ordernarPor.map((ordernar) => (<option key={ordernar.value} value={ordernar.value}>
                                    {ordernar.label}
                                </option>))}
                            </select>
                        </div>
                    </div>
                    <div className="d-flex quatro-colunas">
                        <div className="form-group">
                            <select
                                onChange={(e) => {
                                    this.setState({tipo: e.target.value})
                                    this.setState({acompanhamento: []})
                                }}
                                className="form-control"
                            >
                                {this.state.modulos.includes('JB') && <option value='jb'>Tradicional</option>}
                                {this.state.modulos.includes('Lototime') && <option value='lototime'>Lototime</option>}
                                {this.state.modulos.includes('Bolão') && <option value='Bolao'>Bolão</option>}
                                {this.state.modulos.includes('Bolão de Dezena') &&
                                    <option value='Bolao de Dezenas'>Bolão de Dezenas</option>}
                                {this.state.modulos.includes('Seninha') && <option value='Seninha'>Seninha</option>}
                                {this.state.modulos.includes('Seninha') && <option value='Quininha'>Quininha</option>}
                                {this.state.modulos.includes('Seninha') && <option value='Lotinha'>Lotinha</option>}
                                {this.state.modulos.includes('Sena Brasil') &&
                                    <option value='Sena Brasil'>Sena Brasil</option>}
                                {this.state.modulos.includes('Sena Brasil') &&
                                    <option value='Quina Brasil'>Quina Brasil</option>}
                                {this.state.modulos.includes('Sena Brasil') &&
                                    <option value='Loto Brasil'>Loto Brasil</option>}
                                {this.state.modulos.includes('2 para 500') &&
                                    <option value='Dois Para Quinhentos'>Dois Para Quinhentos</option>}
                                {this.state.modulos.includes('Rifa') && <option value='Rifa'>Rifa</option>}
                            </select>
                        </div>
                        <div className="form-group">
                            <select
                                className="form-control"
                                onChange={(e) => this.mudarLoteria(e.target.value)}
                            >
                                <option value="0">Extrações</option>
                                {this.state.listaLoteria.map((u) => (<option key={u.id} value={u.id}>
                                    {u.nome.toUpperCase()}
                                </option>))}
                            </select>
                        </div>
                        <div className="form-group">
                            <select
                                onChange={(e) => this.setState({data1: e.target.value})}
                                className="form-control"
                            >
                                {this.state.arrayDatas.map((data) => (<option key={data.value} value={data.value}>
                                    {data.label}
                                </option>))}
                            </select>
                        </div>
                        <div className="form-group">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Nº Bilhete"
                                onChange={(e) => this.setState({numeroBilhete: e.target.value})}
                                value={this.state.numeroBilhete}
                                onKeyPress={(event) => {
                                    if (event.key === 'Enter') {
                                        this.requestBilhetes();
                                    }
                                }}
                            />
                        </div>
                        <button
                            type="submit"
                            onClick={() => this.requestBilhetes()}
                            className="btn btn-primary"
                            style={{width: '32%', height: '38px'}}
                        >
                            Buscar
                        </button>
                    </div>
                </div>
                : <div
                    className="conteudo-principal d-flex tres-colunas"
                    style={{padding: '10px 10px 0px 10px'}}
                >
                    <select
                        onChange={(e) => {
                            this.setState({tipo: e.target.value})
                            this.setState({acompanhamento: []})
                        }}
                        className="form-control"
                    >
                        {this.state.modulos.includes('JB') && <option value='jb'>JB</option>}
                        {this.state.modulos.includes('Lototime') && <option value='lototime'>Lototime</option>}
                        {this.state.modulos.includes('Bolão') && <option value='Bolao'>Bolão</option>}
                        {this.state.modulos.includes('Bolão de Dezena') &&
                            <option value='Bolao de Dezenas'>Bolão de Dezenas</option>}
                        {this.state.modulos.includes('Seninha') && <option value='Seninha'>Seninha</option>}
                        {this.state.modulos.includes('Seninha') && <option value='Quininha'>Quininha</option>}
                        {this.state.modulos.includes('Seninha') && <option value='Lotinha'>Lotinha</option>}
                        {this.state.modulos.includes('Sena Brasil') &&
                            <option value='Sena Brasil'>Sena Brasil</option>}
                        {this.state.modulos.includes('Sena Brasil') &&
                            <option value='Quina Brasil'>Quina Brasil</option>}
                        {this.state.modulos.includes('Sena Brasil') &&
                            <option value='Loto Brasil'>Loto Brasil</option>}
                        {this.state.modulos.includes('2 para 500') &&
                            <option value='Dois Para Quinhentos'>Dois Para Quinhentos</option>}
                        {this.state.modulos.includes('Rifa') && <option value='Rifa'>Rifa</option>}
                    </select>
                    <div className="form-group">
                        <select
                            onChange={(e) => this.setState({data1: e.target.value})}
                            className="form-control"
                        >
                            {this.state.arrayDatas.map((data) => (<option key={data.value} value={data.value}>
                                {data.label}
                            </option>))}
                        </select>
                    </div>
                    <div className="form-group">
                        <select
                            onChange={(e) => this.setState({situacao: e.target.value})}
                            className="form-control"
                        >
                            {situacao.map((situacao) => (<option key={situacao.value} value={situacao.value}>
                                {situacao.label}
                            </option>))}
                        </select>
                    </div>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Nº Bilhete"
                        onChange={(e) => this.setState({numeroBilhete: e.target.value})}
                        value={this.state.numeroBilhete}
                    />

                    <button
                        type="submit"
                        onClick={() => this.requestBilhetes()}
                        className="btn btn-primary"
                        style={{height: '38px'}}
                    >
                        Buscar
                    </button>
                </div>}

            {this.state.loadingAtivo && <div className="loader"/>}

            {!this.state.loadingAtivo && (this.state.tipo === 'jb' || this.state.tipo === 'lototime') && this.state.acompanhamento.length > 0 && (

                <ListaResponsiva
                    tHead={tHead}
                    tBody={this.state.acompanhamento}
                    showButtons={true}
                    tButton1={this.abrirModal}
                    tButton2={this.rangerButton2}
                />

            )}

            {!this.state.loadingAtivo && this.state.tipo === 'Bolao' && this.state.acompanhamento.length > 0 && (

                <div className="conteudo-principal p-0 mt-2">

                    <table className="table text-center border">
                        <thead>
                        <tr>
                            <th>Código</th>
                            <th>Data</th>
                            <th>Operador</th>
                            <th>Valor</th>
                            <th>Prêmio</th>
                            <th>Bilhete</th>
                            <PermissionGate permissions={["Remover aposta bolão"]}>
                                <th>Cancelar</th>
                            </PermissionGate>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.acompanhamento && this.state.acompanhamento.map((el, ind) => <tr key={ind}>
                            <td>
                                {el.codigo} <br/>

                                <span
                                    className={`badge badge-${el.situacao === 'pago' ? 'primary' : el.situacao === 'perdedor' ? 'danger' : 'secondary'}`}>
                                        {el.situacao}
                                    </span>

                            </td>
                            <td>
                                {dayjs(el.created_at).format('DD/MM')} <br/>
                                {dayjs(el.created_at).format('HH:mm')}
                            </td>
                            <td>
                                {el.cambista} <br/>
                                {el.gerente}
                            </td>
                            <td>
                                {formatMoney(parseFloat(el.valor).toFixed(2))} <br/>
                                {formatMoney(parseFloat(el.comissao).toFixed(2))}
                            </td>
                            <td>
                                {formatMoney(parseFloat(el.premio).toFixed(2))} <br/>
                                {el.pago_em && dayjs(el.pago_em).format('DD/MM')}
                            </td>
                            <td>
                                <button
                                    type="button"
                                    className="btn btn-info w-100 px-0"
                                    onClick={() => this.setState({aposta: el, exibirModal: true})}
                                >
                                    Ver
                                </button>
                            </td>
                            <PermissionGate permissions={["Remover aposta bolão"]}>
                                <td>
                                    <button
                                        type="button"
                                        className="btn btn-danger w-100 px-0"
                                        onClick={() => this.cancelaBilheteBolao(el.codigo)}
                                    >
                                        Cancelar
                                    </button>
                                </td>
                            </PermissionGate>
                        </tr>)}
                        </tbody>
                    </table>
                </div>

            )}

            {!this.state.loadingAtivo && this.state.acompanhamento.length > 0 && this.state.tipo === 'Bolao de Dezenas' && (

                <div className="conteudo-principal p-0 mt-2">

                    <table className="table text-center border">
                        <thead>
                        <tr>
                            <th>Código</th>
                            <th>Data</th>
                            <th>Operador</th>
                            <th>Valor</th>
                            <th>Prêmio</th>
                            <th>Bolão de Dezenas</th>
                            <th>Bilhete</th>
                            <PermissionGate permissions={["Cancelar palpites seninha"]}>
                                <th>Cancelar</th>
                            </PermissionGate>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.acompanhamento && this.state.acompanhamento.map((el, ind) => <tr key={ind}>
                            <td>
                                {el.codigo} <br/>

                                {el.situacao === 'pendente' && (<span className="badge bg-secondary">
                                        {el.situacao}
                                    </span>)}

                                {el.situacao === 'pago' && (<span className="badge badge-primary">
                                        {el.situacao}
                                    </span>)}

                                {el.situacao === 'cancelado' && (<span className="badge badge-warning">
                                        {el.situacao}
                                    </span>)}

                                {el.situacao === 'perdedor' && (<span className="badge badge-danger">
                                        {el.situacao}
                                    </span>)}

                            </td>
                            <td>
                                {dayjs(el.created_at).format('DD/MM')} <br/>
                                {dayjs(el.created_at).format('HH:mm')}
                            </td>
                            <td>
                                {el.cambista} <br/>
                                {el.gerente}
                            </td>
                            <td>
                                {formatMoney(parseFloat(el.valor).toFixed(2))} <br/>
                                {formatMoney(parseFloat(el.comissao).toFixed(2))}
                            </td>
                            <td>
                                {formatMoney(parseFloat(el.premio).toFixed(2))} <br/>
                                {el.pago_em && dayjs(el.pago_em).format('DD/MM')}
                            </td>
                            <td>
                                {dayjs(el.bolao_data_encerramento).format('DD/MM/YY HH:MM')}
                            </td>
                            <td>
                                <button
                                    className="btn btn-secondary"
                                    onClick={() => this.setState({aposta: el, exibirModal: true})}
                                >
                                    Bilhete
                                </button>
                            </td>
                            <td>
                                <PermissionGate permissions={["Cancelar palpites seninha"]}>
                                    <button
                                        className="btn btn-danger"
                                        onClick={() => this.cancelaBilheteBolaoDezena(el.id)}
                                    >
                                        Cancelar
                                    </button>
                                </PermissionGate>
                            </td>
                        </tr>)}
                        </tbody>
                    </table>
                </div>

            )}

            {!this.state.loadingAtivo && this.state.acompanhamento.length > 0 && (this.state.tipo === 'Dois Para Quinhentos') && (
                <div className="conteudo-principal p-0 mt-2">
                    <table className="table text-center border">
                        <thead>
                        <tr>
                            <th>Código</th>
                            <th>Data</th>
                            <th>Operador</th>
                            <th>Valor</th>
                            <th>Prêmio</th>
                            <th>Bilhete</th>
                            <PermissionGate permissions={["Remover aposta bolão"]}>
                                <th>Cancelar</th>
                            </PermissionGate>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.acompanhamento && this.state.acompanhamento.map((el, ind) => <tr key={ind}>
                            <td>
                                {el.codigo} <br/>

                                {el.situacao === 'pendente' && (<span className="badge bg-secondary">
                                        {el.situacao}
                                    </span>)}

                                {el.situacao === 'pago' && (<span className="badge badge-primary">
                                        {el.situacao}
                                    </span>)}

                                {el.situacao === 'cancelado' && (<span className="badge badge-warning">
                                        {el.situacao}
                                    </span>)}

                                {el.situacao === 'perdedor' && (<span className="badge badge-danger">
                                        {el.situacao}
                                    </span>)}

                            </td>
                            <td>
                                {dayjs(el.created_at).format('DD/MM')} <br/>
                                {dayjs(el.created_at).format('HH:mm')}
                            </td>
                            <td>
                                {el.operador} <br/>
                                {el.gerente}
                            </td>
                            <td>
                                {formatMoney(parseFloat(el.valor).toFixed(2))} <br/>
                                {formatMoney(parseFloat(el.comissao).toFixed(2))}
                            </td>
                            <td>
                                {formatMoney(parseFloat(el.premio).toFixed(2))} <br/>
                                {el.pago_em && dayjs(el.pago_em).format('DD/MM')}
                            </td>
                            <td>
                                <button
                                    className="btn btn-secondary"
                                    onClick={() => this.setState({aposta: el, exibirModal: true})}
                                >
                                    Bilhete
                                </button>
                            </td>
                            <td>
                                <PermissionGate permissions={["Cancelar palpites seninha"]}>
                                    <button
                                        className="btn btn-danger"
                                        onClick={() => this.cancelaBilheteDoisQuinhentos(el.codigo)}
                                    >
                                        Cancelar
                                    </button>
                                </PermissionGate>
                            </td>
                        </tr>)}
                        </tbody>
                    </table>
                </div>
            )}

            {!this.state.loadingAtivo && this.state.acompanhamento.length > 0 && (this.state.tipo === 'Rifa') && (
                <div className="conteudo-principal p-0 mt-2">
                    <table className="table text-center border">
                        <thead>
                        <tr>
                            <th>Código</th>
                            <th>Data</th>
                            <th>Operador</th>
                            <th>Valor</th>
                            <th>Prêmio</th>
                            <th>Bilhete</th>
                            <PermissionGate permissions={["Remover aposta bolão"]}>
                                <th>Cancelar</th>
                            </PermissionGate>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.acompanhamento && this.state.acompanhamento.map((el, ind) => <tr key={ind}>
                            <td>
                                {el.codigo} <br/>

                                {el.situacao === 'pendente' && (<span className="badge bg-secondary">
                                        {el.situacao}
                                    </span>)}

                                {el.situacao === 'pago' && (<span className="badge badge-primary">
                                        {el.situacao}
                                    </span>)}

                                {el.situacao === 'cancelado' && (<span className="badge badge-warning">
                                        {el.situacao}
                                    </span>)}

                                {el.situacao === 'perdedor' && (<span className="badge badge-danger">
                                        {el.situacao}
                                    </span>)}

                            </td>
                            <td>
                                {dayjs(el.created_at).format('DD/MM')} <br/>
                                {dayjs(el.created_at).format('HH:mm')}
                            </td>
                            <td>
                                {el.cambista} <br/>
                                {el.gerente}
                            </td>
                            <td>
                                {formatMoney(parseFloat(el.valor).toFixed(2))} <br/>
                                {formatMoney(parseFloat(el.comissao).toFixed(2))}
                            </td>
                            <td>
                                {formatMoney(parseFloat(el.premio).toFixed(2))} <br/>
                                {el.pago_em && dayjs(el.pago_em).format('DD/MM')}
                            </td>
                            <td>
                                <button
                                    className="btn btn-secondary"
                                    onClick={() => this.setState({aposta: el, exibirModal: true})}
                                >
                                    Bilhete
                                </button>
                            </td>
                            <td>
                                <PermissionGate permissions={["Cancelar palpites seninha"]}>
                                    <button
                                        className="btn btn-danger"
                                        onClick={() => this.cancelaBilheteSeninha(el.id)}
                                    >
                                        Cancelar
                                    </button>
                                </PermissionGate>
                            </td>
                        </tr>)}
                        </tbody>
                    </table>
                </div>

            )}

            {!this.state.loadingAtivo && this.state.acompanhamento.length > 0 && (this.state.tipo === 'Seninha' || this.state.tipo === 'Quininha' || this.state.tipo === 'Lotinha') && (

                <div className="conteudo-principal p-0 mt-2">

                    <table className="table text-center border">
                        <thead>
                        <tr>
                            <th>Código</th>
                            <th>Data</th>
                            <th>Operador</th>
                            <th>Valor</th>
                            <th>Prêmio</th>
                            <th>Concurso</th>
                            <th>Bilhete</th>
                            <PermissionGate permissions={["Cancelar palpites seninha"]}>
                                <th>Cancelar</th>
                            </PermissionGate>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.acompanhamento && this.state.acompanhamento.map((el, ind) => <tr key={ind}>
                            <td>
                                {el.codigo} <br/>

                                {el.situacao === 'pendente' && (<span className="badge bg-secondary">
                                        {el.situacao}
                                    </span>)}

                                {el.situacao === 'pago' && (<span className="badge badge-primary">
                                        {el.situacao}
                                    </span>)}

                                {el.situacao === 'cancelado' && (<span className="badge badge-warning">
                                        {el.situacao}
                                    </span>)}

                                {el.situacao === 'perdedor' && (<span className="badge badge-danger">
                                        {el.situacao}
                                    </span>)}

                            </td>
                            <td>
                                {dayjs(el.created_at).format('DD/MM')} <br/>
                                {dayjs(el.created_at).format('HH:mm')}
                            </td>
                            <td>
                                {el.cambista} <br/>
                                {el.gerente}
                            </td>
                            <td>
                                {formatMoney(parseFloat(el.valor).toFixed(2))} <br/>
                                {formatMoney(parseFloat(el.comissao).toFixed(2))}
                            </td>
                            <td>
                                {formatMoney(parseFloat(el.premio).toFixed(2))} <br/>
                                {el.pago_em && dayjs(el.pago_em).format('DD/MM')}
                            </td>
                            <td>
                                {el.concurso_nome}
                            </td>
                            <td>
                                <button
                                    className="btn btn-secondary"
                                    onClick={() => this.setState({aposta: el, exibirModal: true})}
                                >
                                    Bilhete
                                </button>
                            </td>
                            <td>
                                <PermissionGate permissions={["Cancelar palpites seninha"]}>
                                    <button
                                        className="btn btn-danger"
                                        onClick={() => this.cancelaBilheteSeninha(el.codigo)}
                                    >
                                        Cancelar
                                    </button>
                                </PermissionGate>
                            </td>
                        </tr>)}
                        </tbody>
                    </table>
                </div>

            )}

            {!this.state.loadingAtivo && this.state.acompanhamento.length > 0 && (this.state.tipo === 'Sena Brasil' || this.state.tipo === 'Quina Brasil' || this.state.tipo === 'Loto Brasil') && (

                <div className="conteudo-principal p-0 mt-2">

                    <table className="table text-center border">
                        <thead>
                        <tr>
                            <th>Código</th>
                            <th>Data</th>
                            <th>Operador</th>
                            <th>Valor</th>
                            <th>Prêmio</th>
                            <th>Concurso</th>
                            <th>Bilhete</th>
                            <PermissionGate permissions={["Cancelar palpites seninha"]}>
                                <th>Cancelar</th>
                            </PermissionGate>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.acompanhamento && this.state.acompanhamento.map((el, ind) => <tr key={ind}>
                            <td>
                                {el.codigo} <br/>

                                {el.situacao === 'pendente' && (<span className="badge bg-secondary">
                                        {el.situacao}
                                    </span>)}

                                {el.situacao === 'pago' && (<span className="badge badge-primary">
                                        {el.situacao}
                                    </span>)}

                                {el.situacao === 'cancelado' && (<span className="badge badge-warning">
                                        {el.situacao}
                                    </span>)}

                                {el.situacao === 'perdedor' && (<span className="badge badge-danger">
                                        {el.situacao}
                                    </span>)}

                            </td>
                            <td>
                                {dayjs(el.created_at).format('DD/MM')} <br/>
                                {dayjs(el.created_at).format('HH:mm')}
                            </td>
                            <td>
                                {el.cambista} <br/>
                                {el.gerente}
                            </td>
                            <td>
                                {formatMoney(parseFloat(el.valor).toFixed(2))} <br/>
                                {formatMoney(parseFloat(el.comissao).toFixed(2))}
                            </td>
                            <td>
                                {formatMoney(parseFloat(el.premio).toFixed(2))} <br/>
                                {el.pago_em && dayjs(el.pago_em).format('DD/MM')}
                            </td>
                            <td>
                                {el.concurso_nome}
                            </td>
                            <td>
                                <button
                                    className="btn btn-secondary"
                                    onClick={() => this.setState({aposta: el, exibirModal: true})}
                                >
                                    Bilhete
                                </button>
                            </td>
                            <td>
                                <PermissionGate permissions={["Cancelar palpites seninha"]}>
                                    <button
                                        className="btn btn-danger"
                                        onClick={() => this.cancelaBilheteSenaBrasil(el.codigo)}
                                    >
                                        Cancelar
                                    </button>
                                </PermissionGate>
                            </td>
                        </tr>)}
                        </tbody>
                    </table>
                </div>

            )}

            {!this.state.baixaAutomatica && this.state.acompanhamento.length && (this.state.tipo === 'jb' || this.state.tipo === 'lototime') ? (
                <button
                    style={{marginTop: '10px'}}
                    type="submit"
                    onClick={() => this.pagarTodaasApostas()}
                    className="btn btn-primary buy-all"
                >
                    Pagar todos os bilhetes
                </button>) : (<></>)}
            {this.modalBilhete()}

        </>);
    }
}
